import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { get } from "utils/CmsConnection";
import LinkGroup from "components/footer/LinkGroup";
import Logo from "assets/logo/Logo";

const Footer = () => {
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    get("/footer", signal, setFooterContent);
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const [footerContent, setFooterContent] = useState({});

  return (
    <footer className="py-5">
      <Container className="text-center text-md-left">
        <Row>
          <Col md={6} className="mt-md-0 mt-3">
            <Logo />
            <h5 className="mb-4">{footerContent.title || <Skeleton />}</h5>
            <p>{footerContent.description || <Skeleton count={3} />}</p>
            <div className="d-lg-flex">
              <Col lg={6} className="mb-3">
                {footerContent.phone ? (
                  <>
                    <FontAwesomeIcon icon="phone" className="mr-2 mb-0" />
                    <a href={`tel:${footerContent.phone.number}`}>
                      {footerContent.phone.label}
                    </a>
                  </>
                ) : (
                  <Skeleton />
                )}
              </Col>
              <Col lg={6} className="mb-3">
                {footerContent.phone ? (
                  <>
                    <FontAwesomeIcon icon="envelope" className="mr-2 mb-0" />
                    <a href={`mailto:${footerContent.mail}`}>
                      {footerContent.mail}
                    </a>
                  </>
                ) : (
                  <Skeleton />
                )}
              </Col>
            </div>
          </Col>
          {(footerContent.link_groups
            ? footerContent.link_groups
            : [{}, {}]
          ).map((linkGroup, index) => (
            <LinkGroup data={linkGroup} key={linkGroup.id || index} />
          ))}
        </Row>
        <Row className="d-flex justify-content-center">
          <Col sm={7} md={6} lg={5} xl={4} className="text-center pt-3">
            <small>
              {footerContent.copyright ? (
                <>
                  &copy; {new Date().getFullYear()} {footerContent.copyright}
                </>
              ) : (
                <Skeleton />
              )}
            </small>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
